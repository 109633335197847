<template>
  <a-form
    layout="horizontal"
    :model="formState"
    :rules="rules"
    ref="formRefFilter"
    class="flex flex-col items-center gap-4 md:flex-row"
  >
    <div class="flex flex-col w-full px-8 md:px-4">
      <a-form-item label="Establecimiento" class="mb-0 w-full" name="establecimiento">
        <a-select
          v-model:value="formState.establecimiento"
          @change="handleEstablecimiento"
          class="w-full"
          disabled
        >
          <a-select-option value="">TODOS</a-select-option>
          <a-select-option
            v-for="establecimiento in establecimientos"
            :value="establecimiento.denominacion"
            :key="establecimiento.denominacion"
          >
            {{ establecimiento.denominacion }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="Estado" name="filter" class="mb-0 w-full">
        <a-select v-model:value="formState.filter">
          <a-select-option value="">Cumplen y no cumplen</a-select-option>
          <a-select-option value="1">Cumple</a-select-option>
          <a-select-option value="0">No cumple</a-select-option>
        </a-select>
      </a-form-item>
    </div>

    <div class="flex flex-col w-full px-8">
      <a-form-item label="PERIODO" name="periodo" class="mb-0 w-full">
        <a-select
          v-model:value="formState.idperiodo"
          :value="idperiodoinicio"
          @select="onSelectPeriodo"
        >
          <a-select-option value="">TODOS</a-select-option>
          <a-select-option
            v-for="periodo in periodos"
            :value="periodo.idperiodoevaluacion"
            :key="periodo.idperiodoevaluacion"
          >
            {{
              meses[
                periodo.idperiodoinicio.substr(4, 6) < 10
                  ? periodo.idperiodoinicio.substr(4, 6)[1] - 1
                  : periodo.idperiodoinicio.substr(4, 6) - 1
              ]
            }}
            {{ periodo.idperiodoinicio.substr(0, 4) }} -
            {{
              meses[
                periodo.idperiodofinal.substr(4, 6) < 10
                  ? periodo.idperiodofinal.substr(4, 6)[1] - 1
                  : periodo.idperiodofinal.substr(4, 6) - 1
              ]
            }}
            {{ periodo.idperiodofinal.substr(0, 4) }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="Buscar" class="mb-0 w-full" name="search">
        <a-input v-model:value="formState.search" />
      </a-form-item>
    </div>

    <div class="flex">
      <button @click="applyFilters" type="submit" class="text-center px-3">
        <div class="text-3xl text-blue-400">
          <SearchOutlined />
        </div>
        <p>Buscar</p>
      </button>

      <button @click="cleanFilters" type="button" class="text-center px-3" :disabled="isDisabled">
        <div
          class="text-3xl"
          :class="[isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-purple-500']"
        >
          <ClearOutlined />
        </div>
        <p>Limpiar filtros</p>
      </button>
    </div>
  </a-form>
</template>
<script>
import { reactive, ref, toRaw, onMounted } from "vue";
import { useStore } from "vuex";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons-vue";
import EstablecimientoApi from "@/api/establecimiento";
import { useRoute } from "vue-router";
import ApiIndicador from "@/api/indicador";

export default {
  components: {
    SearchOutlined,
    ClearOutlined
  },
  emits: ["fetchDetalleIndicador"],
  setup(props, { emit }) {
    const store = useStore();
    const provincias = ref([]);
    const distritos = ref([]);
    const establecimientos = ref([]);
    const formRefFilter = ref();
    const isDisabled = ref(true);
    const periodos = ref([]);
    const route = useRoute();
    const idindicador = ref("");

    const usuarioestablecimiento = store.state.user?.usuario?.establecimiento;

    idindicador.value = route.params?.indicador;

    const formState = reactive({
      departamento: "TACNA",
      provincia: store.getters["detalleindicador/provincia"],
      establecimiento: store.getters["detalleindicador/establecimiento"],
      distrito: store.getters["detalleindicador/distrito"],
      filter: store.getters["detalleindicador/filter"],
      search: store.getters["detalleindicador/search"]
    });

    const meses = [
      "ENE",
      "FEB",
      "MAR",
      "ABR",
      "MAY",
      "JUN",
      "JUL",
      "AGO",
      "SET",
      "OCT",
      "NOV",
      "DIC"
    ];

    const rules = {
      search: [
        {
          max: 50,
          message: "La cantidad máxima de caracteres es de 50",
          trigger: "blur"
        }
      ]
    };

    const fetchPeriodos = async () => {
      const id = toRaw(idindicador.value);

      ApiIndicador.getIndicadoresByIDindicador(id)
        .then((response) => {
          console.log(response.data);
          periodos.value = response.data;
        })
        .catch((err) => console.log(err));
    };

    const applyFilters = () => {
      isDisabled.value = false;
      formRefFilter.value
        .validate()
        .then(() => {
          const { filter, search } = toRaw(formState);
          store.dispatch("detalleindicador/setFilter", filter);
          store.dispatch("detalleindicador/setSearch", search);
          emit("fetchDetalleIndicador");
        })
        .catch((err) => console.log(err));
    };

    onMounted(() => {
      const periodo = store.getters["detalleindicador/periodo"];
      formState.idperiodo = periodo;

      cleanFilters();
      fetchPeriodos();
      getProvincias(formState.departamento);
    });

    const getProvincias = (filtro) => {
      EstablecimientoApi.getAllProvincias(filtro)
        .then((response) => {
          provincias.value = response.data;
          formState.distrito = "";
          getDistritos(formState.provincia);
        })
        .catch((err) => console.log(err));
    };

    const getDistritos = (filtro) => {
      EstablecimientoApi.getAllDistritos({ filtro })
        .then((response) => {
          distritos.value = response.data;

          getEstablecimientos();
        })
        .catch((err) => console.log(err));
    };

    const onSelectPeriodo = (periodo) => {
      store.dispatch("detalleindicador/setPeriodo", periodo);
      emit("changePeriodo", 1);
    };

    const getEstablecimientos = () => {
      EstablecimientoApi.getAllEstablecimientos(formState.provincia, formState.distrito)
        .then((response) => {
          establecimientos.value = response.data;
        })
        .catch((err) => console.log(err));
    };

    const cleanFilters = () => {
      isDisabled.value = true;
      formState.filter = "";
      formState.search = "";
      formState.provincia = "";
      formState.distrito = "";
      formState.establecimiento = usuarioestablecimiento;
      store.dispatch("detalleindicador/cleanFilters");
      emit("fetchDetalleIndicador");
    };

    const handleProvincia = (value) => {
      store.dispatch("detalleindicador/setProvincia", value);
      store.dispatch("detalleindicador/setDistrito", "");
      store.dispatch("detalleindicador/setEstablecimiento", "");
      formState.distrito = "";
      formState.establecimientos = "";

      getDistritos(value);
      getEstablecimientos();
    };

    const handleDistrito = (value) => {
      store.dispatch("detalleindicador/setDistrito", value);
      store.dispatch("detalleindicador/setEstablecimiento", "");
      formState.establecimientos = "";
      getEstablecimientos();
    };

    const handleEstablecimiento = (value) => {
      store.dispatch("detalleindicador/setEstablecimiento", value);
    };

    return {
      formState,
      applyFilters,
      establecimientos,
      formRefFilter,
      cleanFilters,
      periodos,
      handleDistrito,
      rules,
      handleProvincia,
      handleEstablecimiento,
      distritos,
      provincias,
      isDisabled,
      onSelectPeriodo,
      meses
    };
  }
};
</script>
